<template>
    <section class="login flex align-center justify-center">
        <img class="login-logo" src="../../assets/images/login/logo1.jpg" alt="">
        <div class="login-container flex">
            <div class="item null-item"></div>
            <div class="item form-content">
                <div class="form-header">
                    <div class="system-name">
                        <img src="../../assets/images/login/hello.png" alt=""/>
                        <span class="name">富航美寓</span>
                    </div>
                    <div class="en text-center">WELCOME TO THE RENTAL MANAGEMENT BACKGROUND</div>
                </div>
                <!--                <el-form class="login-form text-center" :model="formData" @submit.native.prevent>
                                    <el-input clearable v-model="formData.username" placeholder="请输入账号">
                                        <img class="icon-img" slot="prefix" src="../../assets/images/login/account.png" alt=""/>
                                    </el-input>
                                    <el-input show-password clearable v-model="formData.password" placeholder="请输入登录密码">
                                        <img class="icon-img" slot="prefix" src="../../assets/images/login/password.png" alt=""/>
                                    </el-input>

                                    <el-button type="primary" round class="submit-btn" native-type="submit" @click="handleSubmit">登 录</el-button>
                                </el-form>-->
                <div>
                    <el-form :model="formData" @submit.native.prevent>
                        <div style="margin-top: 10px">
                            <el-input clearable v-model="formData.username" placeholder="请输入账号"
                                      :size="windowHeight <= 740 ? 'mini' : ''">
                                <div slot="prefix" style="height: 100%;" class="flex align-center">
                                    <img class="icon-img" src="../../assets/images/login/account.png" alt=""/>
                                </div>
                            </el-input>
                        </div>
                        <div style="margin-top: 10px">
                            <el-input show-password clearable v-model="formData.password" placeholder="请输入登录密码"
                                      :size="windowHeight <= 740 ? 'mini' : ''">
                                <div slot="prefix" style="height: 100%;" class="flex align-center">
                                    <img class="icon-img" src="../../assets/images/login/password.png" alt=""/>
                                </div>
                            </el-input>
                        </div>

                        <div style="margin-top: 10px" class="flex justify-between">
                            <div style="width: 70%;">
                                <el-input clearable v-model="formData.imgCode" placeholder="请输入验证码"
                                          :size="windowHeight <= 740?'mini':''">
                                    <div slot="prefix" style="height: 100%;" class="flex align-center">
                                        <img class="icon-img" src="../../assets/images/login/number-code-icon.png"
                                             alt=""/>
                                    </div>
                                </el-input>
                            </div>
                            <div style="width: 112px;">
                                <!--                                <canvas ref="verify" width="112px" height="40px" @click="handleDraw"></canvas>-->
                                <img :src="numberCodeImg" width="112px" :height="windowHeight <= 740 ? '28px' : '40px'"
                                     @click="handleDraw" alt="">
                            </div>
                        </div>
                        <div style="margin-top: 10px" class="flex justify-between">
                            <div style="width: 70%;">
                                <el-input clearable v-model="formData.smsCode" placeholder="请输入验证码"
                                          :size="windowHeight<=740?'mini':''">
                                    <div slot="prefix" style="height: 100%;" class="flex align-center">
                                        <img class="icon-img" src="../../assets/images/login/sms-code-icon.png" alt=""/>
                                    </div>
                                </el-input>
                            </div>
                            <div>
                                <el-button v-if="!isSmsShow" type="primary" style="width: 112px;"
                                           @click="getSmSVerifyCode" :size="windowHeight <= 740 ? 'mini' : ''">
                                    获取验证码
                                </el-button>
                                <div v-if="isSmsShow">
                                    <el-button type="info" style="width: 112px;" disabled
                                               :size="windowHeight <= 740 ? 'mini' : ''">
                                        {{ countdown }}
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <el-button style="margin-top: 10px; width: 100%;" type="primary" native-type="submit"
                                   :size="windowHeight <= 740 ? 'mini' : ''" @click="handleSubmit">
                            登 录
                        </el-button>
                    </el-form>
                </div>
                <div class="copy-right text-center" style="margin-top: 20px">南宁富航资产管理有限责任公司 桂ICP备2023000618号</div>
            </div>
        </div>
    </section>
</template>

<script>
import {loginApi, menuList, getHutoolVerifyCode, getSmSVerifyCode} from "@/api/login";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {tokenCookies, menuCookies, submenuCookies, buttonCookies} from "@storage/cookies";

export default {
    name: "login",
    data() {
        return {
            formData: {
                username: null,
                password: null,
                imgCode: null,
                smsCode: null,
            },
            numberCodeImg: null,
            imgCode: null,
            isSmsShow: false,
            countdown: 0,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            windowWidth: 0,
            windowHeight: 0,
        };
    },
    methods: {
        handleSubmit() {
            const that = this;
            const {username, password, imgCode, smsCode} = this.formData;
            if (!username) {
                MessageWarning("请输入账号");
                return;
            }
            if (!password) {
                MessageWarning("请输入密码");
                return;
            }
            if (!imgCode) {
                MessageWarning("请输入图片验证码");
                return;
            }
            if (!smsCode) {
                MessageWarning("请输入短信验证码");
                return;
            }
            const data = {username, password, phoneverification: smsCode, pictureverification: imgCode}
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});

            //登录接口
            loginApi(data).then(async (res) => {
                //获取权限
                let menuRes = await menuList();
              console.log(menuRes)
                let {info: {C, F, M, name}} = menuRes;
                //拆分权限
                C = C.map(item => {return {url: item.path, menuName: item.name}});
                M = M.map(item => {return {url: item.path, menuName: item.name}});
                F = F.map(item => {return {url: item.path, menuName: item.name}});
              console.log(  C)
              console.log(  M)
              console.log(  F)
                //保存权限
                menuCookies.set(JSON.stringify(M));
                submenuCookies.set(JSON.stringify(C));
                buttonCookies.set(JSON.stringify(F));
                localStorage.setItem("userName", name);
                localStorage.removeItem("bbxs1");
                loading.close();
                MessageSuccess("登录成功");
                tokenCookies.set("token");
                //跳转首页
                that.$router.push("/home").catch(err => {});
            }).catch(err => {
                loading.close();
                that.handleDraw();
            });
        },

        //图片验证码点击事件
        handleDraw() {
            //调动生成图片验证码方法
            this.getHutoolVerifyCode();
        },

        // 发送验证码按钮倒计时
        startCountdown() {
            const that = this;
            //通过计时器实现倒计时，从60开始倒计时
            setTimeout(() => {
                that.countdown -= 1;
                // 判断是否归零了
                if (that.countdown > 0) {
                    //就行倒计时
                    that.startCountdown();
                } else {
                    that.smsSending = false;
                    //倒计时结束，调用方法判断，内容有没变化
                    that.isSmsShowIsOk();
                }
            }, 1000);
        },
        //生成图片验证码方法
        getHutoolVerifyCode() {
            const that = this;
            //调用生成图片验证码接口
            getHutoolVerifyCode().then(res => {
                //转换文件流
                that.numberCodeImg = URL.createObjectURL(res);
            })
        },

        //短信验证码点击事件
        getSmSVerifyCode() {
            const that = this;
            const {username} = that.formData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            getSmSVerifyCode({username}).then(res => {
                loading.close();
                that.isSmsShow = true;
                that.countdown = 60;
                that.smsSending = true;
                //调用方法开启按钮倒计时
                that.startCountdown();
                that.$alert('短信验证码发送成功，请在10分钟内使用', '提醒', {confirmButtonText: '确定', callback: action => {}});
            }).catch(err => {
                loading.close();
            });
        },

        //获取窗体大小
        getWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },

        //判断数据是有填写完成
        isSmsShowIsOk(){
            const that = this;
            const {username, password, imgCode} = this.formData;
            //判断数据是有填写完成，填写完成，显示发送验证码按钮，未填写完成，显示禁用按钮
            if (username && password && imgCode && !that.smsSending) {
                that.isSmsShow = false;
            } else {
                that.isSmsShow = true;
                //判断倒计时是否接受
                if (that.countdown === 0) {
                    that.countdown = '获取验证码';
                }
            }
        }
    },
    watch: {
        //监听数据，判断展示发送验证码按钮
        'formData.username'() {
            const that = this;
            that.isSmsShowIsOk();
        },
        'formData.password'() {
            const that = this;
            that.isSmsShowIsOk();
        },
        'formData.imgCode'() {
            const that = this;
            that.isSmsShowIsOk();
        }
    },
    created() {
        const that = this;
        //获取窗体大小，用于动态样式
        this.getWindowSize();
        //添加窗体拉伸事件
        window.addEventListener('resize', this.getWindowSize);
        that.$nextTick(() => {
            //获取图片验证码
            that.handleDraw();
            that.isSmsShow = true;
            that.countdown = '获取验证码';
        });
    },
    //组件销毁
    beforeDestroy() {
        //移除窗体拉伸事件
        window.removeEventListener('resize', this.getWindowSize);
    }
};
</script>
<style lang="scss" rel="stylesheet/scss">
.login input {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        font-weight: 400;
        font-family: PingFang SC Regular;
    }
}
</style>
<style lang="scss" scoped>
.login {
    width: 100vw;
    height: 100vh;
    background: url("../../assets/images/login/background.png") no-repeat;
    background-size: 100% 100%;

    .login-logo {
        width: 100px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .login-container {
        width: VW(1276px);
        height: VH(744px);
        background: url("../../assets/images/login/login_form_bg.png") no-repeat;
        background-size: 100% 100%;

        .item {
            height: VH(670px);
        }

        .null-item {
            width: VW(730px);
        }

        .form-content {
            width: VW(546px);
            padding: VH(50px) VW(50px);

            .form-header {
                margin: VH(50px) 0 VH(60px);

                img {
                    width: VW(121px);
                    vertical-align: text-bottom;
                }

                .system-name {
                    color: #333;
                    font-weight: 600;
                    font-size: rem(35px);
                    font-family: PingFang SC Semibold;
                }

                .en {
                    color: #BABABA;
                    font-weight: 300;
                    font-size: rem(16px);
                    font-family: PingFang SC Light;
                    margin-top: VH(5px);
                }
            }

            /deep/ .login-form {
                .el-input {
                    width: VW(382px);
                    height: VH(49px);
                    background: #F7F7F7;
                    border: 1px solid #2996FF;
                    border-radius: 90px;

                    &:first-child {
                        margin-bottom: VH(40px);
                    }

                    .el-input__inner {
                        padding-left: VW(57px);
                        border: none;
                        background: transparent;
                    }

                    .el-input__prefix {
                        left: VW(30px);
                        top: VH(12px);

                        .icon-img {
                            width: VW(17px);
                        }
                    }
                }

                .submit-btn {
                    width: VW(382px);
                    letter-spacing: 10px;
                    margin-top: VH(64px);
                    font-size: rem(20px);
                    font-family: PingFang SC Regular;
                    font-weight: 400;
                    box-shadow: 0 6px 6px rgba(41, 150, 255, 0.45);
                }
            }

            .copy-right {
                color: #BABABA;
                font-size: rem(16px);
                font-weight: 300;
                margin-top: VH(100px);
            }
        }
    }
}

.icon-img {
    width: VW(17px);
}
</style>
