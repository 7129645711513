<template>
    <section>
        <div style="margin-bottom: 10px">
            <el-button type="primary" size="mini" @click="supplementBalance">补充水电余额</el-button>
        </div>
        <!--   <div class="table-title" style="margin: 10px 0;">相关房源</div>-->
        <r-e-table ref="hydropowerTableRef" :dataRequest="getweterelebalance" :columns="wtereleBillColumns"
                   :query="formSearch" :row-style="rowStyle" :height="700">
            <el-table-column slot="toolbar" label="操作" width="180">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="row.waterDataDevice !== 0 && row.electDataDevice !== 0 && row.updateStatus === 0" @click="edit(row)">编辑</span>
                        <span class="table-btn" @click="collection(row)">催收</span>
                        <span class="table-btn" v-if="row.electStatus==='close'"
                              @click="openCloseMeter(row,'open')">开表</span>
                        <span class="table-btn" v-if="row.electStatus==='open'"
                              @click="openCloseMeter(row,'close')">关表</span>
                        <!-- 2024/04/02 张晓瑜新增水电余额转移 -->
                        <span class="table-btn" @click="hydropower(row)">余额转移</span>
<!--                        2024-7-29-陈守亮-水电充值-->
                      <span class="table-btn" v-if="getButtonMenu('TOPUP')" @click="waterElectricity(row)">充值</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <layer-water-electricity-details ref="layerWaterElectricityDetails"/>

        <layer-balance-update ref="layerBalanceUpdate" @handleSearch="handleSearch"/>

        <layer-supplement-balance ref="layerSupplementBalance" @handleSearch="handleSearch" :tenantData="tenantData"/>
        <!-- 2024/04/02 张晓瑜新增水电余额转移 -->
        <layer-hydropower ref="layerHydropower" @handleSearch="handleSearch"/>

        <r-e-dialog title="水电催收提醒" :visible.sync="dialogVisible" width="710px" top="10vh" show-footer
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="100px" size="small" :model="reminder" :rules="reminderRules">
                <el-form-item label="发送方式" prop="type">
                    <el-radio-group v-model="reminder.type">
                        <el-radio-button label="applet">小程序</el-radio-button>
                        <el-radio-button label="note">短信</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="催收款项" prop="costType">
                    <el-radio-group v-model="reminder.costType">
                        <el-radio-button label="water">水费</el-radio-button>
                        <el-radio-button label="elect">电费</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </r-e-dialog>
      <waterElectricityTopup @getDeposit="getDepositmy" ref="dialogrechargeref"/>
    </section>
</template>

<script>
import {wtereleBillColumns} from "@/views/rental-management/tenant-management/data"
import * as management from "@/api/tenant-management";
import {MessageSuccess} from "@custom/message";
import Login from "@/views/login/index.vue";
import {buttonCookies} from "@storage/cookies";

export default {
    name: "hydropower",
    // 合计uuid传参
    props: {
        tenantData: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        layerWaterElectricityDetails: () => import("./components/layer-water-electricity-details"),
        layerBalanceUpdate: () => import("./components/layer-balance-update"),
        layerSupplementBalance: () => import('./components/layer-supplement-balance'),
        layerHydropower:() => import("./components/layer-hydropower"),
        waterElectricityTopup: () => import('./components/water-electricity-topup')
    },
    data() {
        return {
            wtereleBillColumns: wtereleBillColumns(this),
            uuid: null,
            formSearch: {},
            loadingOptions: {
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            dialogVisible: false,
            reminder: {
                uuid: null,
                type: null,
                costType: null,
            },
            reminderRules: {
                type: [{ required: true, message: '请选择发送方式', trigger: 'change' }],
                costType: [{ required: true, message: '请选择催收款项', trigger: 'change' }]
            }
        };
    },

    async created() {
        // 页面初始化
        // 获取用户uuid
        const {uuid} = this.tenantData;
        this.uuid = uuid;
    },
    methods: {
        look(data) {
            this.$refs['layerWaterElectricityDetails'].openDialog(data);
        },
        hydropower(data){
            console.log('1111');
            this.$refs['layerHydropower'].openDialog(data);
        },
        // 获取用户水电余额
        async getweterelebalance(params) {
            params.leasorUuid = this.uuid;
            params.pageSize = 5
            return management.balance(params);
        },
        rowStyle(row) {
            const {row: {updateStatus, waterDataDevice, electDataDevice}} = row;
            if (waterDataDevice === 0 && electDataDevice === 0) return {}
            if (updateStatus === 0) return {backgroundColor: 'red'};
            if (updateStatus === 1) return {backgroundColor: 'green'};
        },
        edit(data) {
            this.$refs['layerBalanceUpdate'].openDialog(data);
        },
        handleSearch(isSearch = true) {
            // 搜索
            this.$refs["hydropowerTableRef"].getTableData(isSearch);
        },
        supplementBalance() {
            this.$refs['layerSupplementBalance'].openDialog();
        },
        collection(data) {
            let that = this;
            const {uuid} = data;
            that.reminder.uuid = uuid;
            that.dialogVisible = true;
        },
        openCloseMeter(data, status) {
            const that = this;
            const {uuid} = data;
            this.$confirm('此操作将' + (status === "open" ? '开表' : '关表') + ', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                management.openCloseMeter({uuid, status}).then(res => {
                    MessageSuccess("操作成功");
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },
        clickSubmit() {
            const that = this;
            this.$refs['formPublish'].validate(valid => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    const {uuid, type, costType} = that.reminder;
                    alert({uuid, type, costType})
                    management.waterElectReminder({uuid, type, costType}).then(res => {
                        MessageSuccess("催收成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });

        },     //获取按钮权限数组
      getButtonMenu(menu) {
        return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
      },

        clickCancel() {
            this.reminder = {
                uuid: null,
                type: null,
                costType: null
            }
            this.dialogVisible = false;
        },
      waterElectricity(e){
          // alert('点击充值')
        console.log('充值事件',e)
        // this.$refs.REFTopup.openDialog(e.apartmentUuid,e.leasorUuid)
        this.$refs.dialogrechargeref.ruleForm= {
          type: '',
          amount: '',
          paymentMethod: '',
          apartmentUuid:e.apartmentUuid,
          cancelTime: new Date().toLocaleString()
        }
        this.$refs.dialogrechargeref.uuid = e.leasorUuid
        this.$refs.dialogrechargeref.dialogVisible = true
        this.$refs.dialogrechargeref.tenantData = e
      },
      getDepositmy(){
        console.log(this.$parent)
        this.handleSearch()
        // this.$emit('getDeposit');

      }

    }
}
</script>

<style lang="scss" scoped>
/deep/ .r-e-table {
    .el-table .cell {
        //white-space: pre-wrap;
        text-align: center;
    }
}
</style>